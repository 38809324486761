/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Entry from './Entry';

const randomInt = () =>
    Math.random()
        .toString(36)
        .substring(7);

const Entries = ({ saveLocalData, saveContinue, quoteId }) => {
    const localData = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));
    const policyType = localData.policy.policyType.policyTypeValue;
    const [btnDisabled, setBtnDisabled] = useState(true);

    function enableButton() {
        setBtnDisabled(false);
    }

    function disableButton() {
        setBtnDisabled(true);
    }

    const calculateRemainUsage = entries => {
        let max = 100;

        entries.forEach(ent => {
            // if (ent.id !== defaultValues.id) {
            if (
                ent.roleType === 'registered_and_main' ||
                ent.roleType === 'additional_driver' ||
                ent.roleType === 'main' ||
                ent.roleType === 'registered_and_addition'
            ) {
                max -= ent.percentageUsed;
            }
            // }
        });

        return max;
    };
    const defaultEntry = (id, roleType, { gradeNotes, otherEntries }) => ({
        id,
        roleType:
            roleType ?? localData?.ownership?.length > 0
                ? 'additional_driver'
                : 'registered_and_main',
        title: undefined,
        gender: undefined,
        firstName: undefined,
        lastName: undefined,
        mobile: undefined,
        mobileCountryCode: 64,
        yearsLicenseHeld: undefined,
        addressSearch: undefined,
        fullAddress: undefined,
        suburb: undefined,
        city: undefined,
        postalCode: undefined,
        streetAddress: undefined,
        email: undefined,
        licenseType: undefined,
        licenseId: undefined,
        // fileUpload: undefined,
        dob_year: undefined,
        dob_month: undefined,
        dob_day: undefined,
        date_issued_year: undefined,
        date_issued_month: undefined,
        date_issued_day: undefined,
        grade_notes: gradeNotes,
        percentageUsed: calculateRemainUsage(otherEntries ?? []),
        isValid: false,
    });

    const [gradeValue, setGradeValue] = useState(
        localData?.ownership?.length > 0 ? localData.ownership[0].grade_notes : null
    );
    const gradeNotes = {
        'grade-1': null,
        'grade-2': "2 years' previous insurance history (no at fault claims)",
        'grade-3': '1 year insurance history (no at fault claims)',
        'grade-4': 'No previous insurance history',
    };
    const formData =
        localData && localData.ownership
            ? localData.ownership
            : [defaultEntry(randomInt(), null, { gradeValue, otherEntries: [] })];

    const [entries, setEntries] = useState(formData);
    const [submitted, setSubmitted] = useState(false);
    // const [showSaveButton, setShowSaveButton] = useState(true);

    const calculateMaxRangeValue = () => {
        let num = 0;

        entries.forEach(ent => {
            if (
                ent.roleType === 'registered_and_main' ||
                ent.roleType === 'additional_driver' ||
                ent.roleType === 'main' ||
                ent.roleType === 'registered_and_addition'
            ) {
                num += ent.percentageUsed;
            }
        });

        return num;
    };

    const validationMessage = () => {
        let message = null;

        // Check if usage adds up to 100
        if (calculateMaxRangeValue() !== 100) {
            message = 'Usage must add up to 100%';
        }

        // Check if there is a main driver
        const mainDriverExists = !!entries.find(
            o => o.roleType === 'main' || o.roleType === 'registered_and_main'
        );

        if (!mainDriverExists) {
            message = 'Assign a main driver';
        }

        // Check if there is an owner driver
        const ownerExists = !!entries.find(
            o => o.roleType === 'registered_and_addition' || o.roleType === 'registered_and_main'
        );

        if (!ownerExists) {
            message = 'Assign an owner';
        }

        // Check if main driver has more usage than additional driver
        let additonalDriverHasMoreUsageThanMainDriver = false;
        const mainDriver = entries.find(
            o => o.roleType === 'main' || o.roleType === 'registered_and_main'
        );

        entries.forEach(entry => {
            if (mainDriver?.id !== entry.id && entry.percentageUsed >= mainDriver?.percentageUsed) {
                additonalDriverHasMoreUsageThanMainDriver = true;
            }
        });

        if (additonalDriverHasMoreUsageThanMainDriver) {
            message = "Main driver's usage must be greater than all other additional drivers";
        }

        // Check if main driver has more usage than additional driver
        const numberOfAdditionalDrivers = entries.filter(
            o => o.roleType === 'additional_driver' || o.roleType === 'registered_and_addition'
        );

        if (numberOfAdditionalDrivers.length > 2) {
            message = 'Up to 2 additional drivers allowed, please remove one.';
        }

        if (gradeValue === null) {
            message = 'Please select At Fault accidents/claims info';
        }

        if (!!entries.find(entry => entry.percentageUsed < 10)) {
            message = `The minimum percentage used must be equal to or greater than 10% (${
                entries.find(entry => entry.percentageUsed < 10).firstName
            } ${entries.find(entry => entry.percentageUsed < 10).lastName})`;
        }

        return message;
    };

    const onSubmit = () => {
        setSubmitted(true);
        // const interior = data.formData;

        // window.dataLayer.push({
        //     // eslint-disable-line
        //     event: 'valuationClaimSubmission',
        //     step: 1,
        // });

        if (validationMessage() === null) {
            const entriesWithGrade = entries.map(entry => ({
                ...entry,
                grade_notes: gradeValue,
            }));
            saveLocalData({ ownership: entriesWithGrade });
            saveContinue();
        }
    };

    const findWithAttr = (array, attr, value) => {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    };

    const updatedGrades = [
        {
            label:
                'None (has had a driver’s licence <span class="emphasis">less than</span> 2 years)',
            value: 'grade-2',
        },
        {
            label:
                'None (has had a driver’s licence <span class="emphasis">more than</span> 2 years)',
            value: 'grade-1',
        },
        { label: '1-2 At Fault accidents/claims', value: 'grade-3' },
        { label: '3 or more At Fault accidents/claims', value: 'grade-4' },
    ];

    // const updateDriverTypeBasedOnSiblings = newEntries => {
    //     return newEntries;
    // };

    return (
        <div className="ownership-entry-wrapper">
            <ul className="ownership-entry-list">
                {entries.length ? (
                    entries.map((entry, i) => {
                        return (
                            <Entry
                                key={entry.id}
                                index={i}
                                AllEntries={entries}
                                numberOfEntries={entries.length}
                                toggleShowSaveButton={val => console.log(val)}
                                defaultValues={entry}
                                deleteEntry={() => {
                                    const originalEntries = entries;
                                    originalEntries.splice(i, 1);
                                    setEntries([...originalEntries]);
                                }}
                                updateEntry={(values, id, callback) => {
                                    var originalEntries = entries;
                                    const index = findWithAttr(entries, 'id', id) ?? 0;
                                    let updatedEntry = entries.find(o => o.id === id);
                                    updatedEntry = { ...updatedEntry, ...values };

                                    // originalEntries = updateDriverTypeBasedOnSiblings(
                                    //     originalEntries
                                    // );

                                    originalEntries.splice(index, 1, updatedEntry);
                                    setEntries([...originalEntries]);
                                }}
                                enableButton={enableButton}
                                disableButton={disableButton}
                            />
                        );
                    })
                ) : (
                    <p>Please add a driver</p>
                )}
            </ul>
            <div className="footer-actions btn-right">
                <button
                    className={`btn submit ${
                        entries.length < (policyType === 'Specified' ? 3 : 2)
                            ? undefined
                            : 'disabled'
                    }`}
                    type="button"
                    disabled={btnDisabled}
                    onClick={() => {
                        if (entries.length < (policyType === 'Specified' ? 3 : 2)) {
                            const newArray = [
                                ...entries,
                                defaultEntry(randomInt(), 'additional_driver', {
                                    otherEntries: entries,
                                }),
                            ];
                            // eslint-disable-next-line no-var
                            setEntries(newArray);
                        }
                        disableButton();
                    }}
                >
                    Add a driver
                </button>
            </div>

            <div className="at-fault-claims">
                <div>
                    In the <span className="emphasis">last 3 years</span> how many{' '}
                    <strong>At Fault</strong> vehicle accidents/claims has the client had?*
                </div>

                <div>
                    {updatedGrades.map((grade, index) => {
                        return (
                            <div key={index}>
                                <label htmlFor={grade.value}>
                                    <input
                                        type="radio"
                                        name="grade"
                                        id={grade.value}
                                        value={grade.value}
                                        checked={gradeValue === grade.value}
                                        onChange={e => setGradeValue(e.target.value)}
                                    />
                                    <span dangerouslySetInnerHTML={{ __html: grade.label }}></span>
                                </label>
                            </div>
                        );
                    })}
                </div>

                <div>
                    <p>At Fault accidents/claims are defined as</p>
                    <ol>
                        <li>
                            those where the client caused an accident{' '}
                            <strong>
                                (regardless of whether or not they had insurance or made a claim),
                            </strong>
                            <br />
                            <strong>or</strong>
                        </li>
                        <li>those where an excess was paid or was deducted</li>
                    </ol>
                </div>
            </div>

            {validationMessage() !== null && submitted ? (
                <div className="error-message-inline">{validationMessage()}</div>
            ) : (
                undefined
            )}
            <div className="footer-actions">
                <button
                    className={`c-button c-button--primary ${
                        entries.filter(e => e.isValid === false).length !== 0
                            ? 'disabled'
                            : undefined
                    }`}
                    type="button"
                    onClick={
                        entries.filter(e => e.isValid === false).length !== 0 ? null : onSubmit
                    }
                >
                    <span className="c-button__text">Save and continue</span>
                </button>
            </div>
        </div>
    );
};

Entries.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
};

Entries.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    quoteId: PropTypes.string.isRequired,
};

export default Entries;
